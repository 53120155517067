import React from "react";
import { X, Download, MessageCircle } from "lucide-react";
import ReactGA from "react-ga4";

const ShareModal = ({
  isOpen,
  onClose,
  imageUrl,
  behavior,
}) => {
  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleDownloadClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Bad Behavior Download Button",
      label: "Download Bad Behavior Image",
    });
  };

  const handleWhatsAppShare = async () => {
    try {
      ReactGA.event({
        category: "User",
        action: "Clicked WhatsApp Share Button",
        label: "Share Bad Behavior Image on WhatsApp",
      });

      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const file = new File([blob], "hargawiki-share.png", {
        type: "image/png",
      });

      if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: "ماتكونش هرڭاوي",
          text: `${behavior}\n\nwww.hargawiki.ma`,
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;

          const a = document.createElement("a");
          a.href = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${behavior}\n\nwww.hargawiki.ma`
          )}`;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          const downloadLink = document.createElement("a");
          downloadLink.href = base64data;
          downloadLink.download = "hargawiki-share.png";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      }
    } catch (error) {
      console.error("Error sharing to WhatsApp:", error);
      // Fallback to basic sharing
      // window.open(
      //   `https://api.whatsapp.com/send?text=${encodeURIComponent(`${behavior}\n\nwww.hargawiki.ma`)}`,
      //   '_blank'
      // );
    }
  };

  return (
    <div
      onClick={handleOutsideClick}
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
    >
      <div className="bg-white text-black rounded-lg shadow-lg p-6 max-w-lg w-full relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-red-500"
        >
          <X className="w-6 h-6" />
        </button>

        {/* Header */}
        <h2 className="text-2xl font-bold mb-4 text-center">
          شارك على مواقع التواصل
        </h2>

        {/* Image preview */}
        {imageUrl && (
          <img
            src={imageUrl}
            alt="Generated Share Preview"
            className="w-full rounded-lg border border-gray-300 mb-4"
          />
        )}

        {/* Buttons */}
        <div className="flex flex-col gap-4 md:flex-row">
          {/* WhatsApp Share Button */}
          <button
            onClick={handleWhatsAppShare}
            className="flex-1 p-3 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-colors flex items-center justify-center gap-2"
          >
            مشاركة على مواقع التواصل
            <MessageCircle className="w-5 h-5" />
          </button>
          {/* Download Image Button */}
          <a
            href={imageUrl}
            download="#matkounch_hargawi - hargawiki.ma.png"
            className="flex-1 p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition-colors flex items-center justify-center gap-2"
            onClick={handleDownloadClick}
          >
            تحميل الصورة
            <Download className="w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
