import React, { useState, useEffect } from "react";
import { Send, Lightbulb, Star, ArrowBigUp, ArrowBigDown } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga4";
import DOMPurify from "dompurify";
import axios from "axios";

const BehaviorItem = React.memo(
  ({
    item,
    isChecked,
    onCheck,
    onShare,
    onFavorite,
    isFavorite,
    solution = "كن مثالاً للسلوك الحضاري في مجتمعك",
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [votingState, setVotingState] = useState({
      upvoted: false,
      downvoted: false,
      upvotes: item.upvotes,
      downvotes: item.downvotes,
    });

    useEffect(() => {
      const savedVotingState = localStorage.getItem(`voting_${item._id}`);
      if (savedVotingState) {
        const parsedState = JSON.parse(savedVotingState);
        setVotingState((prevState) => ({
          ...prevState,
          upvoted: parsedState.upvoted,
          downvoted: parsedState.downvoted,
        }));
      }
    }, [item._id]);

    const trackBulbClick = () => {
      ReactGA.event({
        category: "User",
        action: "Clicked Bulbe Button",
        label: "Bulbe Button Click",
      });
    };

    const handleBulbeClick = () => {
      trackBulbClick();
      setIsOpen(!isOpen);
    };

    const handleVote = async (behaviorId, voteType) => {
      setVotingState((prevState) => {
        let newUpvotes = prevState.upvotes;
        let newDownvotes = prevState.downvotes;
        let newUpvoted = prevState.upvoted;
        let newDownvoted = prevState.downvoted;

        if (voteType === "up") {
          if (prevState.upvoted) {
            newUpvoted = false;
            newUpvotes--;
          } else if (prevState.downvoted) {
            newDownvoted = false;
            newDownvotes--;
            newUpvoted = true;
            newUpvotes++;
          } else {
            newUpvoted = true;
            newUpvotes++;
          }
        }

        if (voteType === "down") {
          if (prevState.downvoted) {
            newDownvoted = false;
            newDownvotes--;
          } else if (prevState.upvoted) {
            newUpvoted = false;
            newUpvotes--;
            newDownvoted = true;
            newDownvotes++;
          } else {
            newDownvoted = true;
            newDownvotes++;
          }
        }

        const votePayload = {
          upvoted: newUpvoted,
          downvoted: newDownvoted,
          upvotes: newUpvotes,
          downvotes: newDownvotes,
        };

        localStorage.setItem(
          `voting_${behaviorId}`,
          JSON.stringify(votePayload)
        );

        return votePayload;
      });

      try {
        const response = await axios.patch(
          `https://hargawiki-backend.onrender.com/api/behaviors/${behaviorId}/vote`,
          {
            voteType,
            currentUpvoted: votingState.upvoted,
            currentDownvoted: votingState.downvoted,
          }
        );

        setVotingState((prevState) => ({
          upvoted: response.data.upvoted,
          downvoted: response.data.downvoted,
          upvotes: response.data.upvotes,
          downvotes: response.data.downvotes,
        }));
      } catch (error) {
        console.error("Error voting:", error);

        setVotingState((prevState) => {
          localStorage.removeItem(`voting_${behaviorId}`);

          return {
            upvoted: prevState.upvoted,
            downvoted: prevState.downvoted,
            upvotes: prevState.upvotes,
            downvotes: prevState.downvotes,
          };
        });
      }
    };

    return (
      <div className="flex flex-col space-y-2">
        <div className="flex items-center">
          {/* Voting buttons Container */}
          <div>
            <div
              className={`flex flex-col md:justify-between w-8 rounded-lg transition-colors duration-300 justify-start`}
            >
              <button
                className={`flex flex-col items-center group
            ${
              votingState.upvoted
                ? "text-green-600"
                : "text-white/70 hover:text-white"
            }`}
                onClick={() => handleVote(item._id, "up")}
              >
                <ArrowBigUp
                fill={`${votingState.upvoted ? "#65a30d" : ""}`}
                  className={`w-6 h-6 transition-all duration-300 
              ${
                votingState.upvoted
                  ? "scale-125 text-green-600"
                  : "group-hover:scale-110"
              }`}
                />
                <span className="text-sm">{votingState.upvotes}</span>
              </button>
              <div className="h-px bg-white/20 my-1 self-center w-6/12"></div>
              <button
                className={`flex flex-col items-center group
            ${
              votingState.downvoted
                ? "text-red-600"
                : "text-white/70 hover:text-white"
            }`}
                onClick={() => handleVote(item._id, "down")}
              >
                <span className="text-sm">{votingState.downvotes}</span>
                <ArrowBigDown
                fill={`${votingState.downvoted ? "red" : ""}`}
                  className={`w-6 h-6 transition-all duration-300 
              ${
                votingState.downvoted
                  ? "scale-125 text-red-600"
                  : "group-hover:scale-110"
              }`}
                />
              </button>
            </div>
          </div>
          {/* Item Text and buttons */}
          <div
            className={`relative flex items-center justify-between bg-black border border-yellow-400 p-3 rounded-lg flex-col md:flex-row grow md:self-stretch
            ${
              isOpen
                ? "border-b-transparent rounded-b-none"
                : "border-b-yellow-400"
            }`}
          >
            <div className="flex self-stretch items-center mb-2 md:mb-0">
              <span
                className={`text-lg text-right text-white ${
                  isChecked ? "line-through" : ""
                }`}
                style={{ direction: "rtl" }}
              >
                {item.behavior}
              </span>
            </div>

            <div className="flex flex-row self-end md:self-center">
              <button
                onClick={onShare}
                className="p-2 hover:bg-[#25D366] hover:text-black rounded-full transition-colors group"
              >
                <Send className="w-5 h-5 group-hover:rotate-12 transition-transform" />
              </button>
              <button
                onClick={handleBulbeClick}
                className={`p-2 hover:bg-yellow-400 hover:text-black rounded-full transition-colors group ${
                  isOpen ? "bg-yellow-400 text-black" : ""
                }`}
              >
                <Lightbulb
                  className={`w-5 h-5 transition-transform`}
                />
              </button>
              <button
                onClick={() => onFavorite(item._id)}
                className="p-2 hover:text-yellow-400 rounded-full transition-colors group"
              >
                <Star
                  className={`w-5 h-5 group-hover:scale-110 transition-transform ${
                    isFavorite
                      ? "fill-yellow-400 text-yellow-400 scale-110"
                      : "hover:text-yellow-400"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
        {/* Item Description */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0, marginTop: 0 }}
              animate={{ height: "auto", opacity: 1, marginTop: 0 }}
              exit={{ height: 0, opacity: 0, marginTop: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden mt-0"
            >
              <div className="border border-yellow-400 border-t-0 rounded-b-lg p-4 bg-yellow-400/10">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(solution),
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default BehaviorItem;