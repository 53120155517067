import React from "react";

const Privacy = () => {
  return (
    <div className="my-8 text-left text-white" dir="ltr">
      <h1 className="text-yellow-400 text-center text-3xl font-notoKufi font-bold mb-6">Privacy Policy</h1>
      At HargaWiki.ma, we value your privacy and are committed to protecting the
      personal information you share with us. This policy explains how we handle
      data when you use Google Authentication to access our platform.<br></br>
      <br></br>
      Information We Collect:<br></br>
      When you log in using Google Authentication, we may collect the following
      information from your Google account: Basic Profile Information: Your
      name, email address, and profile picture. Unique User ID: A unique
      identifier provided by Google. We do not collect or store your password or
      other sensitive information.<br></br>
      <br></br>
      How We Use Your Information:<br></br>
      We use the information collected through Google Authentication for the
      following purposes: To identify you and provide personalized features
      (e.g., tracking your votes or contributions). To ensure secure access to
      our platform. To improve the functionality and experience of our website.
      We will never sell, rent, or share your personal information with third
      parties without your consent.<br></br>
      <br></br>
      Data Storage and Security:<br></br>
      Your data is stored securely on our servers, and we take all reasonable
      steps to protect it from unauthorized access or misuse. We use
      industry-standard security measures, including encryption and secure
      server protocols. Third-Party Services Google Authentication is provided
      by Google LLC. When you use this feature, you agree to Google’s Privacy
      Policy.
    </div>
  );
};

export default Privacy;