import React from "react";
import { SocialIcon } from "react-social-icons";
import ReactGA from "react-ga4";

const Footer = () => {
  const handleSocialButtonClick = (social) => {
    ReactGA.event({
      category: "User",
      action: `Clicked ${social} Button`,
      label: `${social} Button Click`,
    });
  };

  return (
    <footer className="bg-black text-white text-right" dir="rtl">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center text-sm text-yellow-400/60 border-t border-yellow-400 pt-6"></div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* First Column: Main Links */}
          <div className="flex flex-col space-y-6 text-center md:text-right items-center md:items-start">
            <h4 className="text-xl font-bold text-yellow-400 mb-4">
              روابط سريعة
            </h4>
            <div
              className="flex flex-col space-y-4 text-center md:text-right items-center md:items-start"
              style={{ marginTop: "0" }}
            >
              {[
                { href: "/", text: "الرئيسية" },
                { href: "/about", text: "شكون حنا؟" },
                { href: "/contact", text: "تواصل معانا" },
                { href: "/privacy", text: "Privacy Policy" },
                { href: "/terms", text: "Terms of Use" },
              ].map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  className="text-lg font-bold text-white hover:text-yellow-500 transition-colors w-fit"
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          {/* Second Column: Additional Links */}
          <div className="flex flex-col space-y-6 text-center md:text-right items-center md:items-start">
            <h4 className="text-xl font-bold text-yellow-400 mb-4">المزيد</h4>
            <div
              className="flex flex-col space-y-4 text-center md:text-right"
              style={{ marginTop: "0" }}
            >
              {[
                { href: "/quotes", text: "أقوال و اقتباسات" },
                // { href: "/hargawimeter", text: "HargawiMeter" },
              ].map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  className="text-lg font-bold text-white hover:text-yellow-500 transition-colors w-fit"
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          {/* Third Column: Social Media Icons */}
          <div className="flex flex-col text-center md:text-right space-y-6">
            <h4 className="text-xl font-bold text-yellow-400 mb-4">
              تابعنا على مواقع التواصل
            </h4>
            <div
              className="flex space-x-reverse space-x-2 text-center md:text-right mr-auto ml-auto md:mr-0"
              style={{ marginTop: "0" }}
            >
              {/* <SocialIcon target="_blank" onClick={() => handleSocialButtonClick('youtube')} className="hover:bg-yellow-500 rounded-full transition-colors" bgColor="transparent" fgColor="#FFFFFF" url="https://www.youtube.com/channel/UCulavrdoiJQ1Db6cdpzIkCg" /> */}
              {/* <SocialIcon target="_blank" onClick={() => handleSocialButtonClick('tiktok')} className="hover:bg-yellow-500 rounded-full transition-colors" bgColor="transparent" fgColor="#FFFFFF" url="https://www.tiktok.com/@hargawiki.ma" /> */}
              {/* <SocialIcon target="_blank" onClick={() => handleSocialButtonClick('discord')} className="hover:bg-yellow-500 rounded-full" bgColor="transparent" fgColor="#FFFFFF" url="https://discord.gg/Yx4MJSH9PW" /> */}
              {/* <SocialIcon target="_blank" onClick={() => handleSocialButtonClick('reddit')} className="hover:bg-yellow-500 rounded-full transition-colors" bgColor="transparent" fgColor="#FFFFFF" url="https://www.reddit.com/r/HargaWiki/" /> */}
              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("facebook")}
                className="hover:bg-yellow-500 rounded-full transition-colors"
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://www.facebook.com/profile.php?id=61569356964988"
              />
              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("twitter")}
                className="hover:bg-yellow-500 rounded-full transition-colors"
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://x.com/MatkonchHargawi"
              />

              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("instagram")}
                className="hover:bg-yellow-500 rounded-full transition-colors"
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://www.instagram.com/matkounch.hargawi"
              />
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-12 text-center text-sm text-yellow-400/60 border-t border-gray-800 pt-2">
          <p>{new Date().getFullYear()} - HargaWiki.ma</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
