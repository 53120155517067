export const quotes = [
    {
      text: "كن التغيير الذي تريد أن تراه في العالم.",
      author: "المهاتما غاندي",
      profession: "المهنة أو الوصف",
    },
    {
      text: "كن لطيفًا كلما أمكن، ودائمًا ما يكون ذلك ممكنًا.",
      author: "الدالاي لاما",
      profession: "المهنة أو الوصف",
    },
    {
      text: "لا تضيع المزيد من الوقت في الجدال حول ما يجب أن يكون عليه الإنسان الجيد. كن واحدًا.",
      author: "ماركوس أوريليوس",
      profession: "المهنة أو الوصف",
    },
    {
      text: "القدوة ليست الشيء الأساسي في التأثير على الآخرين، إنها الشيء الوحيد.",
      author: "ألبرت شفايتزر",
      profession: "",
    },
    {
      text: "إنّ أكمل المؤمنين إيمانًا أحسنهم أخلاقًا.",
      author: "الإمام علي (عليه السلام)",
      profession: "",
    },
    {
      text: "لا تسأل عما يمكن لبلدك أن يفعله من أجلك، بل اسأل عما يمكنك فعله من أجل بلدك.",
      author: "جون إف كينيدي",
      profession: "",
    },
    {
      text: "ما يهم في الحياة ليس مجرد العيش، بل ما نحدثه من فرق في حياة الآخرين.",
      author: "نيلسون مانديلا",
      profession: "",
    },
    {
      text: "إذا قام كل شخص بتنظيف عتبة منزله، سيصبح العالم بأسره نظيفًا.",
      author: "الأم تيريزا",
      profession: "",
    },
    {
      text: "احترم نفسك وسيحترمك الآخرون.",
      author: "كونفوشيوس",
      profession: "",
    },
    {
      text: "ارفع كلماتك، وليس صوتك. فالأمطار هي التي تنمي الزهور، وليس الرعد.",
      author: "جلال الدين الرومي",
      profession: "",
    },
    {
      text: "إنّ من خياركم أحسنكم أخلاقًا.",
      author: "النبي محمد ﷺ",
      profession: "",
    },
    {
      text: "الجميع يفكر في تغيير العالم، ولكن لا أحد يفكر في تغيير نفسه.",
      author: "ليو تولستوي",
      profession: "",
    },
    {
      text: "كم هو رائع أن لا يحتاج أحد إلى الانتظار لحظة واحدة قبل البدء في تحسين العالم.",
      author: "آن فرانك",
      profession: "",
    },
    {
      text: "إذا لم تدافع عن شيء، ستقع فريسة لأي شيء.",
      author: "مالكوم إكس",
      profession: "",
    },
    {
      text: "التغيير الذي نسعى إليه لن يأتي من الحكومة وحدها. سيأتي من كل واحد منا يقوم بما يستطيع لجعل هذا البلد مكانًا أفضل.",
      author: "باراك أوباما",
      profession: "",
    },
    {
      text: "بمفردنا نستطيع أن نفعل القليل؛ معًا يمكننا أن نفعل الكثير.",
      author: "هيلين كيلر",
      profession: "",
    },
    {
      text: "رحلة الألف ميل تبدأ بخطوة واحدة.",
      author: "لاو تزو",
      profession: "",
    },
    {
      text: "الخلق الحسن يذيب الخطايا كما تذيب الشمس الجليد.",
      author: "الإمام الشافعي",
      profession: "",
    },
    {
      text: "نصبح ما نفعله بشكل متكرر. لذا، فإن التميز ليس عملاً، بل عادة.",
      author: "أرسطو",
      profession: "",
    },
    {
      text: "احترم الآخرين في أفعالهم وأقوالهم، وسيردون الاحترام بمثله.",
      author: "سقراط",
      profession: "",
    },
    {
      text: "حاسبوا أنفسكم قبل أن تحاسبوا، وزنوا أعمالكم قبل أن توزن عليكم.",
      author: "عمر بن الخطاب (رضي الله عنه)",
      profession: "",
    },
    {
      text: "أعظم الحسنات هو تحسين الخلق.",
      author: "الإمام الغزالي",
      profession: "",
    },
    {
      text: "المجتمع الجيد هو الذي يسود فيه القانون، وليس القانون فقط، بل الأخلاق أيضاً.",
      author: "نجيب محفوظ",
      profession: "",
    },
    {
      text: "ما تفعله اليوم هو ما يحدد مستقبلك.",
      author: "إبراهيم الفقي",
      profession: "",
    },
    {
      text: "الفرد في المجتمع كالخلية في الجسد، إذا فسدت، فسد المجتمع كله.",
      author: "عبد الوهاب المسيري",
      profession: "",
    },
    {
      text: "إن تعليم الناس وتثقيفهم في حد ذاته ثروة كبيرة نعتز بها.",
      author: "طه حسين",
      profession: "",
    },
    {
      text: "لا حرية لمجتمع بلا أخلاق.",
      author: "الأمير عبد القادر الجزائري",
      profession: "",
    },
    {
      text: "ما رأيت أحداً أكثر تبسماً من رسول الله صلى الله عليه وسلم.",
      author: "عبد الله بن الحارث",
      profession: "",
    },
    {
      text: "إذا لم يكن في قلب الإنسان الحب والتقدير، فإن حياته ليست إلا لحظة عابرة.",
      author: "جبران خليل جبران",
      profession: "",
    },
    {
      text: "النظام ليس فقط علامة التقدم، بل أساس التقدم.",
      author: "كونفوشيوس",
      profession: "",
    },
    {
      text: "من علامات الإيمان حسن الخلق.",
      author: "الحسن البصري",
      profession: "",
    },
    {
      text: "التربية تبدأ من البيت، والسلوك يبدأ من الأسرة.",
      author: "الإمام مالك",
      profession: "",
    },
    {
      text: "أنت لست قطرة في المحيط، بل أنت المحيط في قطرة.",
      author: "جلال الدين الرومي",
      profession: "",
    },
    {
      text: "العقل الكبير الذي يهتم بالآخرين يجعل الحياة أكثر جمالًا للجميع.",
      author: "مصطفى لطفي المنفلوطي",
      profession: "",
    },
    {
      text: "العدل أساس العمران، ومتى انعدم العدل، انهار العمران.",
      author: "الإمام محمد عبده",
      profession: "",
    },
    {
      text: "إنما الأمم الأخلاق ما بقيت، فإن همُ ذهبت أخلاقهم ذهبوا.",
      author: "أحمد شوقي",
      profession: "",
    },
    {
      text: "ابدأ بنفسك، وانظر كيف يتغير العالم.",
      author: "جلال الدين الرومي",
      profession: "",
    },
    {
      text: "من يريد خير البلاد فلا يسعى إلا في إتقان التربية و بعد ذلك يأتي له جميع ما يطلبه، بدون إتعاب فكر ولا إجهاد نفس.",
      author: "الامام محمد عبده",
      profession: "",
    },
  ];