import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { Menu, X } from "lucide-react";
import logo from "../site-logo.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const handleSocialButtonClick = (platform) => {
    console.log(`Clicked ${platform}`);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="bg-black pb-4 md:pt-4 px-0 shadow-lg border-b border-yellow-400">
      <div className="container mx-auto">
        <div className="flex items-center">
          {/* Logo - Left */}
          <div className="flex-shrink-0">
            <a href="/" className="block">
              <img
                src={logo}
                className="w-20 hover:opacity-90 transition-opacity"
                alt="logo"
              />
            </a>
          </div>

          {/* Desktop Navigation - Center */}
          <nav className="hidden md:flex items-center gap-8 text-white mx-auto">
            <a
              href="/"
              className={`text-lg hover:text-yellow-400 transition-colors relative group`}
            >
              <span>الرئيسية</span>
              <span
                className={`absolute -bottom-1 left-0 h-0.5 bg-yellow-400 transition-all ${
                  isActive("/") ? "w-full" : "w-0 group-hover:w-full"
                }`}
              ></span>
            </a>
            <a
              href="/quotes"
              className={`text-lg hover:text-yellow-400 transition-colors relative group ${
                isActive("/quotes") ? "text-yellow-400" : ""
              }`}
            >
              <span>أقوال و اقتباسات</span>
              <span
                className={`absolute -bottom-1 left-0 h-0.5 bg-yellow-400 transition-all ${
                  isActive("/quotes") ? "w-full" : "w-0 group-hover:w-full"
                }`}
              ></span>
            </a>
            {/* <a
              href="/hargawimeter"
              className={`text-lg hover:text-yellow-400 transition-colors relative group ${
                isActive("/hargawimeter") ? "text-yellow-400" : ""
              }`}
            >
              <span>HargawiMeter</span>
              <span
                className={`absolute -bottom-1 left-0 h-0.5 bg-yellow-400 transition-all ${
                  isActive("/hargawimeter")
                    ? "w-full"
                    : "w-0 group-hover:w-full"
                }`}
              ></span>
            </a> */}
            <a
              href="/about"
              className={`text-lg hover:text-yellow-400 transition-colors relative group ${
                isActive("/about") ? "text-yellow-400" : ""
              }`}
            >
              <span>شكون حنا؟</span>
              <span
                className={`absolute -bottom-1 left-0 h-0.5 bg-yellow-400 transition-all ${
                  isActive("/about") ? "w-full" : "w-0 group-hover:w-full"
                }`}
              ></span>
            </a>
            <a
              href="/contact"
              className={`text-lg hover:text-yellow-400 transition-colors relative group ${
                isActive("/contact") ? "text-yellow-400" : ""
              }`}
            >
              <span>تواصل معانا</span>
              <span
                className={`absolute -bottom-1 left-0 h-0.5 bg-yellow-400 transition-all ${
                  isActive("/contact") ? "w-full" : "w-0 group-hover:w-full"
                }`}
              ></span>
            </a>
          </nav>

          {/* Desktop Social Icons - Far Left */}
          <div className="hidden md:block">
            <div className="flex space-x-reverse space-x-1">
              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("facebook")}
                className="hover:scale-110 transition-transform rounded-full"
                style={{ width: "36px", height: "36px" }}
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://www.facebook.com/profile.php?id=61569356964988"
              />
              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("twitter")}
                className="hover:scale-110 transition-transform rounded-full"
                style={{ width: "36px", height: "36px" }}
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://x.com/MatkonchHargawi"
              />
              <SocialIcon
                target="_blank"
                onClick={() => handleSocialButtonClick("instagram")}
                className="hover:scale-110 transition-transform rounded-full"
                style={{ width: "36px", height: "36px" }}
                bgColor="transparent"
                fgColor="#FFFFFF"
                url="https://www.instagram.com/matkounch.hargawi"
              />
            </div>
          </div>

          {/* Mobile Menu Button - Right */}
          <div className="flex md:hidden mr-auto gap-6">
            <div className="flex md:hidden justify-center">
              <div className="flex space-x-reverse space-x-1">
                <SocialIcon
                  target="_blank"
                  onClick={() => handleSocialButtonClick("facebook")}
                  className="hover:scale-110 transition-transform hover:bg-yellow-400 rounded-full"
                  style={{ width: "36px", height: "36px" }}
                  bgColor="transparent"
                  fgColor="#FFFFFF"
                  url="https://www.facebook.com/profile.php?id=61569356964988"
                />
                <SocialIcon
                  target="_blank"
                  onClick={() => handleSocialButtonClick("twitter")}
                  className="hover:scale-110 transition-transform hover:bg-yellow-400 rounded-full"
                  style={{ width: "36px", height: "36px" }}
                  bgColor="transparent"
                  fgColor="#FFFFFF"
                  url="https://x.com/MatkonchHargawi"
                />
                <SocialIcon
                  target="_blank"
                  onClick={() => handleSocialButtonClick("instagram")}
                  className="hover:scale-110 transition-transform hover:bg-yellow-400 rounded-full"
                  style={{ width: "36px", height: "36px" }}
                  bgColor="transparent"
                  fgColor="#FFFFFF"
                  url="https://www.instagram.com/matkounch.hargawi"
                />
              </div>
            </div>
            <button
              onClick={toggleMenu}
              className="text-white hover:text-yellow-400 transition-colors"
            >
              {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
            </button>
          </div>

          {/* Mobile Social Icons */}
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden ${isMenuOpen ? "block" : "hidden"} pt-4 pb-2`}
        >
          <nav className="flex flex-col gap-4 text-white">
            <a
              href="/"
              className={`text-lg hover:text-yellow-400 transition-colors py-2 border-b border-gray-800`}
            >
              الرئيسية{" "}
            </a>
            <a
              href="/quotes"
              className={`text-lg hover:text-yellow-400 transition-colors py-2 border-b border-gray-800 ${
                isActive("/quotes") ? "text-yellow-400" : ""
              }`}
            >
              أقوال و اقتباسات
            </a>
            {/* <a
              href="/hargawimeter"
              className={`text-lg hover:text-yellow-400 transition-colors py-2 border-b border-gray-800 ${
                isActive("/hargawimeter") ? "text-yellow-400" : ""
              }`}
            >
              HargawiMeter
            </a> */}
            <a
              href="/about"
              className={`text-lg hover:text-yellow-400 transition-colors py-2 border-b border-gray-800 ${
                isActive("/about") ? "text-yellow-400" : ""
              }`}
            >
              شكون حنا؟
            </a>
            <a
              href="/contact"
              className={`text-lg hover:text-yellow-400 transition-colors py-2 border-b border-gray-800 ${
                isActive("/contact") ? "text-yellow-400" : ""
              }`}
            >
              تواصل معانا
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
