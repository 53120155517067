import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import ReactGA from "react-ga4";
import QuotesPage from "./pages/QuotesPage";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
// import HargawiMeterQuiz from "./pages/HargawiMeterQuiz";
import BadBehaviorsList from "./components/BadBehaviorsList";
import Footer from "./components/Footer";
import Header from "./components/Header";
import SubmitBehaviorForm from "./components/SubmitBehaviorForm";


function App() {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send("pageview", window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const handleRouteChange = (location) => {
      ReactGA.send("pageview", location.pathname + location.search);
    };

    navigate(handleRouteChange);
  }, [navigate]);

  return (
      <div
        className="flex flex-col min-h-screen bg-black text-yellow-400 p-4 md:p-8 md:pt-0"
        dir="rtl"
      >
        <Header />
        <main className=" max-w-3xl mx-auto flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <BadBehaviorsList />
                  <SubmitBehaviorForm />
                </>
              }
            />
            <Route path="/quotes" element={<QuotesPage />} />
            {/* <Route path="/hargawimeter" element={<HargawiMeterQuiz />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
          </Routes>
        </main>
        <Footer />
      </div>
  );
}

export default App;