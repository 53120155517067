import React from "react";

const headers = [
  "مانكـــونش هـرڭـاوي...!",
  // '!Tahargawit No More',
  // 'مـاتـكـونـش هـرڭـاوي... كن متحضر!',
  // 'التغيير كيبدا بيا و بيك.',
  // 'لا لتاهرڭاويت... نعم للتحضر!',
  // 'هرڭاوي؟ لا شكراً!',
  // 'زيرو تاهرڭاويت... زيرو فوضى!',
  // '!Say NO To Tahargawit'
];

export default function HeroSection() {
  let i = Math.floor(Math.random() * headers.length);
  return (
    <div className="text-2xl font-bold text-center text-white my-6 md:my-16">
      <h1 className="text-4xl md:text-6xl font-bold mb-4 text-center text-yellow-400">
        {headers[i]}
      </h1>
      <div className="text-lg md:text-3xl">
        مغربي متحضر =<span className="text-white"> مـ</span>
        <span className="text-white">ـغـ</span>
        <span className="text-white">ـر</span>
        <span className="text-white">ب</span>
        <span> </span>
        <span className="text-white">أ</span>
        <span className="text-white">جـ</span>
        <span className="text-white">ـمـ</span>
        <span className="text-white">ـل</span>
      </div>
    </div>
  );
}
