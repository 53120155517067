import React from "react";

const AboutUs = () => {
  return (
    <div className="my-8 text-left text-white" dir="rtl">
      <h1 className="text-yellow-400 text-center text-3xl font-notoKufi font-bold mb-6">شكون حنا؟</h1>
      <div className="text-white text-right">
        <span>
          موقع HargaWiki.ma هو منصة رقمية تهدف إلى تسليط الضوء على السلوكيات غير
          المدنية التي تؤثر سلباً على الحياة اليومية في المجتمع المغربي. من خلال
          تقديم محتوى بسيط ومباشر، نعمل على رفع مستوى الوعي حول هذه الممارسات
          وتشجيع الأفراد على التحلي بروح المسؤولية والاحترام تجاه الآخرين
          والمحيط.
        </span>
        <br></br>
        <br></br>
        <span>
          يتيح الموقع للمستخدمين استكشاف قائمة شاملة من السلوكيات غير المرغوبة
          في مختلف المجالات، مثل الأماكن العامة، المواصلات، البيئة، العلاقات
          الاجتماعية، وغيرها. بالإضافة إلى ذلك، يمكن للمستخدمين المشاركة بفعالية
          من خلال اقتراح أفكار جديدة أو مشاركة السلوكيات مع الآخرين عبر تصميمات
          جذابة تهدف إلى نشر الرسالة بشكل أوسع.
        </span>
        <br></br>
        <br></br>
        <h2>رؤيتنا:</h2>
        <span>
          نسعى إلى إحداث تغيير إيجابي في المجتمع المغربي من خلال تعزيز ثقافة
          الاحترام والتعاون، وتشجيع الأفراد على التفكير في تأثير أفعالهم اليومية
          على الآخرين وعلى البيئة المحيطة.
        </span>
        <br></br>
        <br></br>
        <h2>مهمتنا:</h2>
        <ul>
          <li>نشر الوعي حول السلوكيات السلبية وآثارها.</li>
          <li>توفير منصة تشاركية لتبادل الأفكار والمساهمة في تحسين المجتمع.</li>
          <li>تحفيز النقاشات البناءة حول أهمية التصرفات الإيجابية.</li>
        </ul>
        <br></br>
        <h2>قيمنا:</h2>
        <ul>
          <li>
            <strong>المسؤولية:</strong> تشجيع الجميع على تحمل مسؤولية أفعالهم.
          </li>
          <li>
            <strong>التعاون:</strong> الإيمان بأن التغيير الإيجابي يبدأ من العمل
            الجماعي.
          </li>
          <li>
            <strong>الإبداع:</strong> تقديم محتوى بسيط وفعال يصل للجميع.
          </li>
        </ul>
        <br></br>
        <p>
          موقع HargaWiki.ma ليس مجرد منصة توعوية؛ إنه دعوة مفتوحة للجميع للإسهام
          في بناء مجتمع أكثر وعياً واحتراماً.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
