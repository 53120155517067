import React from "react";

const Terms = () => {
  return (
    <div className="my-8 text-left text-white" dir="ltr">
      <h1 className="text-yellow-400 text-center text-3xl font-notoKufi font-bold mb-6">Terms of Use</h1>
      Welcome to HargaWiki.ma. By accessing or using our website, you agree to
      be bound by these Terms of Use. Please read them carefully.
      <br></br>
      <br></br>
      1. Acceptance of Terms<br></br>
      By accessing, browsing, or using this website, you acknowledge that you
      have read, understood, and agreed to these Terms of Use. If you do not
      agree with these terms, please do not use our platform.
      <br></br>
      <br></br>
      2. Purpose of the Website<br></br>
      HargaWiki.ma is a platform designed to raise awareness about uncivil
      behaviors in Moroccan society. Users can view, share, and contribute
      content to help foster positive social change.
      <br></br>
      <br></br>
      3. User Contributions<br></br>
      Users may submit behaviors, solutions, or other content for inclusion on
      the website. By submitting content, you grant us a non-exclusive,
      royalty-free, perpetual, and irrevocable license to use, display, modify,
      and distribute your contribution on the platform. You agree not to submit
      content that is defamatory, offensive, illegal, or infringes on the rights
      of others.
      <br></br>
      <br></br>
      4. User Responsibilities<br></br>
      When using the website, you agree to:
      <br></br>
      Use the platform only for lawful purposes. Refrain from posting spam,
      false information, or inappropriate content. Respect other users and
      engage in constructive discussions. Failure to comply with these
      responsibilities may result in temporary or permanent suspension of your
      access to the platform.
      <br></br>
      <br></br>
      5. Voting and Interaction<br></br>
      Users can interact with behaviors listed on the site by upvoting or
      downvoting. We reserve the right to monitor and moderate voting activity
      to prevent abuse (e.g., vote manipulation or spam).
      <br></br>
      <br></br>
      6. Disclaimer of Liability<br></br>
      The content on this site is for informational purposes only. While we
      strive to ensure accuracy, we do not guarantee the completeness or
      reliability of the information provided. HargaWiki.ma is not responsible
      for any consequences resulting from the use or misuse of the information
      shared on the platform.
      <br></br>
      <br></br>
      7. Privacy Policy<br></br>
      Your use of HargaWiki.ma is also governed by our Privacy Policy. By using
      this site, you consent to the collection and use of information as
      described in our Privacy Policy.
      <br></br>
      <br></br>
      8. Intellectual Property<br></br>
      All content on the website, including text, graphics, and design, is the
      property of HargaWiki.ma unless otherwise stated. You may not copy,
      reproduce, or redistribute site content without prior permission.
      <br></br>
      <br></br>
      9. Termination of Use<br></br>
      We reserve the right to terminate or restrict access to the site for any
      user who violates these Terms of Use or engages in harmful behavior.
      <br></br>
      <br></br>
      10. Modifications<br></br>
      HargaWiki.ma may update these Terms of Use at any time. Changes will be
      posted on this page, and continued use of the website implies acceptance
      of the revised terms.
      <br></br>
      <br></br>
      11. Contact Us<br></br>
      If you have questions or concerns about these Terms of Use, please contact
      us at:
      <br></br>
      Email: hargawiki@gmail.com
    </div>
  );
};

export default Terms;