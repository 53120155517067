import React, { useState, useEffect, useRef } from "react";
import { Share2, X, Download, MessageCircle } from "lucide-react";
import ReactGA from "react-ga4";
import { quotes } from "../data/quotes";

const COLOR_PALETTES = [
  "from-amber-100 to-amber-300",
  "from-emerald-100 to-emerald-300",
  "from-blue-100 to-blue-300",
  "from-purple-100 to-purple-300",
  "from-pink-100 to-pink-300",
  "from-teal-100 to-teal-300",
  "from-indigo-100 to-indigo-300",
  "from-rose-100 to-rose-300",
];

const QuotesPage = () => {
  const [randomizedQuotes, setRandomizedQuotes] = useState([]);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(null);
  const [shareError, setShareError] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const shuffledQuotes = [...quotes]
      .sort(() => Math.random() - 0.5)
      .map((quote, index) => ({
        ...quote,
        background: `bg-gradient-to-br ${
          COLOR_PALETTES[index % COLOR_PALETTES.length]
        }`,
      }));
    setRandomizedQuotes(shuffledQuotes);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isModalOpen]);

  const handleShareClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Quote Share Button",
      label: "Share Quote Image",
    });
  };

  const handleDownloadClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Quote Download Button",
      label: "Download Quote Image",
    });
  };

  const handleWhatsAppShare = async () => {
    try {
      ReactGA.event({
        category: "User",
        action: "Clicked WhatsApp Share Button",
        label: "Share Bad Behavior Image on WhatsApp",
      });

      const response = await fetch(currentImageUrl);
      const blob = await response.blob();

      const file = new File([blob], "hargawiki-share.png", {
        type: "image/png",
      });

      if (navigator.share && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: "ماتكونش هرگاوي",
          text: `${currentQuote}\n\nwww.hargawiki.ma`,
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;

          const a = document.createElement("a");
          a.href = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${currentQuote}\n\nwww.hargawiki.ma`
          )}`;
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          const downloadLink = document.createElement("a");
          downloadLink.href = base64data;
          downloadLink.download = "hargawiki-share.png";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      }
    } catch (error) {
      console.error("Error sharing to WhatsApp:", error);
      // Fallback to basic sharing
      // window.open(
      //   `https://api.whatsapp.com/send?text=${encodeURIComponent(
      //     `${currentQuote}\n\nwww.hargawiki.ma`
      //   )}`,
      //   "_blank"
      // );
    }
  };

  const generateShareImage = async (text) => {
    try {
      setIsGeneratingImage(true);
      setShareError("");

      const notoKufiFont = new FontFace(
        "Noto Kufi Arabic",
        "url(https://fonts.gstatic.com/s/notokufiarabic/v21/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFlKp.woff2)",
        { weight: "400" }
      );

      const notoKufiBoldFont = new FontFace(
        "Noto Kufi Arabic",
        "url(https://fonts.gstatic.com/s/notokufiarabic/v21/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFlKp.woff2)",
        { weight: "700" }
      );

      await Promise.all([notoKufiFont.load(), notoKufiBoldFont.load()]);

      document.fonts.add(notoKufiFont);
      document.fonts.add(notoKufiBoldFont);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 1080;
      canvas.height = 1080;

      const gradient = ctx.createLinearGradient(
        0,
        0,
        canvas.width,
        canvas.height
      );
      gradient.addColorStop(0, "#000000");
      gradient.addColorStop(0.5, "#1a1a1a");
      gradient.addColorStop(1, "#000000");
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < canvas.width; i += 40) {
        for (let j = 0; j < canvas.height; j += 40) {
          if (Math.random() > 0.5) {
            ctx.fillStyle = "rgba(255, 215, 0, 0.02)";
            ctx.fillRect(i, j, 2, 2);
          }
        }
      }

      const cornerSize = 150;

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(cornerSize, 0);
      ctx.lineTo(0, cornerSize);
      ctx.closePath();
      ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(canvas.width, canvas.height);
      ctx.lineTo(canvas.width - cornerSize, canvas.height);
      ctx.lineTo(canvas.width, canvas.height - cornerSize);
      ctx.closePath();
      ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
      ctx.fill();

      const contentPadding = 60;
      ctx.strokeStyle = "#FFD700";
      ctx.lineWidth = 3;
      ctx.strokeRect(
        contentPadding,
        contentPadding,
        canvas.width - contentPadding * 2,
        canvas.height - contentPadding * 2
      );

      const dotRadius = 12;
      const cornerOffset = 60;
      const corners = [
        [cornerOffset, cornerOffset],
        [canvas.width - cornerOffset, cornerOffset],
        [cornerOffset, canvas.height - cornerOffset],
        [canvas.width - cornerOffset, canvas.height - cornerOffset],
      ];

      corners.forEach(([x, y]) => {
        ctx.fillStyle = "#FFD700";
        ctx.beginPath();
        ctx.arc(x, y, dotRadius, 0, Math.PI * 2);
        ctx.fill();
      });

      ctx.fillStyle = "#FFD700";
      ctx.textAlign = "center";
      ctx.direction = "rtl";
      ctx.textBaseline = "middle";

      const wrapText = (context, text, maxWidth) => {
        const words = text.split(" ").reverse();
        const lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = context.measureText(word + " " + currentLine).width;

          if (width < maxWidth) {
            currentLine = word + " " + currentLine;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines.reverse();
      };

      ctx.font = 'bold 180px "Noto Kufi Arabic"';
      ctx.fillStyle = "rgba(255, 215, 0, 0.15)";
      ctx.fillText("❝", 180, canvas.height / 2 - 180);
      ctx.fillText("❞", canvas.width - 180, canvas.height / 2 + 300);

      ctx.font = 'bold 56px "Noto Kufi Arabic"';
      ctx.fillStyle = "#FFD700";
      const maxWidth = canvas.width - contentPadding * 4;
      const lines = wrapText(ctx, text.split("\n")[0], maxWidth);
      const lineHeight = 90;
      const totalTextHeight = lines.length * lineHeight;
      const startY = (canvas.height - totalTextHeight) / 2;

      ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
      ctx.shadowBlur = 15;
      ctx.shadowOffsetX = 3;
      ctx.shadowOffsetY = 3;

      lines.forEach((line, index) => {
        ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
      });

      ctx.font = '38px "Noto Kufi Arabic"';
      ctx.textAlign = "left";
      ctx.fillText(
        text.split("\n")[1],
        contentPadding + 120,
        startY + totalTextHeight + lineHeight
      );

      ctx.shadowColor = "transparent";
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;

      const bottomBarHeight = 80;
      const bottomBarY = canvas.height - bottomBarHeight - contentPadding;

      ctx.fillStyle = "rgba(255, 215, 0, 0.1)";
      ctx.fillRect(
        contentPadding,
        bottomBarY,
        canvas.width - contentPadding * 2,
        bottomBarHeight
      );

      ctx.textAlign = "center";
      ctx.font = 'bold 32px "Noto Kufi Arabic"';
      ctx.fillStyle = "#FFD700";
      ctx.fillText(
        "#ماتـكونـش_هرگاوي   |   www.HargaWiki.ma",
        canvas.width / 2,
        bottomBarY + bottomBarHeight / 2
      );

      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, "image/png");
      });

      return blob;
    } catch (error) {
      console.error("Error generating image:", error);
      throw new Error("Failed to generate image");
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const handleShare = async (quote) => {
    handleShareClick();
    try {
      const imageBlob = await generateShareImage(
        `"${quote.text}"\n- ${quote.author}`
      );
      const currentImageUrl = URL.createObjectURL(imageBlob);
      setCurrentImageUrl(currentImageUrl);
      setCurrentQuote(quote);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Sharing failed:", error);
    }
  };

  return (
    <div className="my-8" dir="rtl">
      <div className="max-w-3xl mx-auto space-y-8">
        <h1 className="text-yellow-400 text-center text-3xl font-notoKufi font-bold mb-6">
          أقوال و اقتباسات
        </h1>

        {randomizedQuotes.map((quote, index) => (
          <div
            key={index}
            className={`
              relative overflow-hidden rounded-2xl shadow-lg 
              
              ${quote.background} p-8
            `}
          >
            <div className="relative z-10">
              <blockquote className="text-2xl text-slate-900 font-semibold mb-6 font-notoKufi leading-relaxed">
                "{quote.text}"
              </blockquote>

              <div className="flex justify-between items-center">
                <button
                  onClick={() => handleShare(quote)}
                  className="
                    bg-white/20 hover:bg-white/40 
                    text-slate-800 p-2 rounded-full 
                    transition-all duration-300
                  "
                >
                  <Share2 size={24} />
                </button>

                <p className="text-xl font-bold text-slate-800">
                  - {quote.author}
                </p>
              </div>
            </div>

            <div className="absolute inset-0 opacity-10 bg-white mix-blend-overlay"></div>
          </div>
        ))}
      </div>

      {isModalOpen && currentImageUrl && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 ">
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative"
          >
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-slate-600 hover:text-yellow-900"
            >
              <X size={24} />
            </button>
            {/* Header */}
            <h2 className="text-2xl font-bold mb-4 text-center text-black">
              شارك على مواقع التواصل
            </h2>
            <img
              src={currentImageUrl}
              alt="اقتباس مشترك"
              className="w-full rounded-xl mb-6 shadow-lg"
            />

            {/* Buttons */}
            <div className="flex flex-col gap-4 md:flex-row">
              {/* WhatsApp Share Button */}
              <button
                onClick={handleWhatsAppShare}
                className="flex-1 p-3 bg-green-600 text-white rounded-lg hover:bg-green-500 transition-colors flex items-center justify-center gap-2"
              >
                مشاركة على مواقع التواصل
                <MessageCircle className="w-5 h-5" />
              </button>
              {/* Download Image Button */}
              <a
                onClick={handleDownloadClick}
                href={currentImageUrl}
                download="quote - hargawiki.ma.png"
                className="flex-1 p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-500 transition-colors flex items-center justify-center gap-2"
              >
                تحميل الصورة
                <Download className="w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuotesPage;