import React, { useState, useRef } from "react";
import { Send } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";

const API_URL = "https://hargawiki-backend.onrender.com";

export default function SubmitBehaviorForm() {
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    behavior: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setFormError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.behavior.trim()) {
      setFormError("يرجى إدخال السلوك");
      return;
    }

    setIsSubmitting(true);

    try {
      const token = await recaptchaRef.current.executeAsync();
      if (!token) {
        setFormError("يرجى إكمال التحقق");
        return;
      }

      const response = await fetch(`${API_URL}/api/submit-behavior`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "omit",
        body: JSON.stringify({
          ...formData,
          recaptchaToken: token,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to submit");
      }

      const result = await response.json();

      if (!result.success) {
        throw new Error(result.error || "Failed to submit");
      }

      // Reset form
      setFormData({
        name: "",
        email: "",
        behavior: "",
      });
      setFormSuccess(true);

      // Reset reCAPTCHA
      recaptchaRef.current.reset();

      // Clear success message after 3 seconds
      setTimeout(() => {
        setFormSuccess(false);
      }, 3000);
    } catch (error) {
      console.error("Submission error:", error);
      setFormError("حدث خطأ أثناء الإرسال. يرجى المحاولة مرة أخرى.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-2 mt-28 text-center">
        أضف سلوك هـرگـاوي بغيتيه ينقارض من المجتمع!
      </h2>
      <div className="mt-8 border border-yellow-400 rounded-lg p-6">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block mb-2">
              الإسم (اختياري)
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full bg-black border border-yellow-400 rounded-lg p-3 text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div>
            <label htmlFor="email" className="block mb-2">
              البريد الإلكتروني (اختياري)
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full bg-black border border-yellow-400 rounded-lg p-3 text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
          </div>

          <div>
            <label htmlFor="behavior" className="block mb-2">
              السلوك غير الحضاري*
            </label>
            <textarea
              id="behavior"
              name="behavior"
              placeholder="الهرڭاوي هو ..."
              value={formData.behavior}
              onChange={handleInputChange}
              className="w-full bg-black border border-yellow-400 rounded-lg p-3 text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 min-h-[100px]"
              required
            />
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="invisible"
            />
          </div>

          {formError && (
            <div className="text-red-500 text-center">{formError}</div>
          )}

          {formSuccess && (
            <div className="text-green-500 text-center">
              تم إرسال السلوك بنجاح!
            </div>
          )}

          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full p-4 bg-yellow-400 text-black rounded-lg transition-colors flex items-center justify-center gap-2 ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-yellow-500"
            }`}
          >
            {isSubmitting ? "جاري الإرسال..." : "إرسال"}
            <Send className="w-5 h-5" />
          </button>
        </form>
      </div>
    </div>
  );
}