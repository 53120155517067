import React from "react";

const ContactUs = () => {
  return (
    <div className="min-h-full mt-8 mb-32 text-left text-white" dir="rtl">
      <h1 className="text-yellow-400 text-center text-3xl font-notoKufi font-bold mb-6">تواصل معانا</h1>
      <div className="text-white text-right">
        <p>
          إذا كان لديك أي استفسارات عامة، ترغب في الإبلاغ عن مشكلة في الموقع، أو
          لديك أفكار حول شراكات أو تعاون محتمل... لا تتردد في التواصل معنا.
          يمكنك مراسلتنا عبر البريد الإلكتروني، وسنحرص على الرد عليك في أقرب وقت
          ممكن:
        </p>
        <br></br>
        <br></br>
        <p className="text-center text-yellow-400 text-2xl">
          hargawiki@gmail.com
        </p>
      </div>
    </div>
  );
};

export default ContactUs;